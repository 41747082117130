import React, { useState, useEffect } from "react";
import AdminTopBar from "./AdminTopBar"; // Reusing the top bar component
import { useApplicationForm } from "../../Contexts/ApplicationFormContext";
import { db } from "../../Services/firebase-config.js";
import { useAuth } from "../../Contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  
  doc,
  getDocs,
  collection,
  updateDoc,
  onSnapshot,
  limit,
  query,
  where,
  orderBy,
  getCountFromServer,
} from "firebase/firestore";

const ReviewDashboard = () => {
  const { currentUser } = useAuth();
  const { userData } = useApplicationForm();

  const [teamMembers, setTeamMembers] = useState([]);
  const [totalSubmissions, setTotalSubmissions] = useState(0);
  const [totalSubmissionsEvaluated, setTotalSubmissionsEvaluated] = useState(0);
  const [positions, setPositions] = useState({});
  const [department, setDepartment] = useState(userData?.department ?? "");
  const [observations, setObservations] = useState("");
  const navigate = useNavigate();

  const handleDepartmentChange = (e) => {
    setDepartment(e.target.value);
  };
  const handleObservationChange = (e) => {
    setObservations(e.target.value);
  };

  const handleSubmitObservations = () => {
    // Logic to submit observations (you can update this based on your actual implementation)
    alert(`Observations submitted: ${observations}`);
  };

  const handleViewReport = () => {
    // Navigate to the DepartmentReport page and pass the department data
    navigate(`/department-report`, { state: { department } });
  };

  const fetchTotalSubmissions = async () => {
    try {
      const submissionsRef = collection(db, "applications");
      const q = query(
        submissionsRef,
        where("submitted", "==", true),
        where("valueChain", "==", department)
      );
      const snapshot = await getCountFromServer(q);
      const submissionsCount = snapshot.data().count;
      setTotalSubmissions(submissionsCount);
    } catch (error) {
      console.error("Error fetching total submissions: ", error);
    }
  };

// Define a cache object
let cache = {
  reviewedCount: null,
  lastFetched: null,
};

const CACHE_TTL = 60000; // Cache duration in milliseconds (e.g., 60 seconds)

const fetchTotalSubmissionsEvaluated = async () => {
  const now = Date.now();

  // Check if the cached data is still valid
  if (cache.reviewedCount && cache.lastFetched && now - cache.lastFetched < CACHE_TTL) {
    setTotalSubmissionsEvaluated(cache.reviewedCount);
    return;
  }

  try {
    const submissionsRef = collection(db, "applications");

    const q = query(
      submissionsRef,
      where("submitted", "==", true),
      where("valueChain", "==", department),
    );

    const snapshot = await getDocs(q);

    let reviewedCount = 0;
    snapshot.forEach((doc) => {
      const data = doc.data();
      const reviews = data.reviews || {};
      const isReviewed = Object.values(reviews).some((review) => review.reviewed === true);

      if (isReviewed) {
        reviewedCount++;
      }
    });

    // Cache the result
    cache.reviewedCount = reviewedCount;
    cache.lastFetched = now;

    setTotalSubmissionsEvaluated(reviewedCount);
  } catch (error) {
    console.error("Error fetching reviewed submissions: ", error);
  }
};

  

  const fetchTeamMembers = () => {
    const q = query(
      collection(db, "users"),
      where("role", "==", "Reviewer"),
      where("department", "==", department ?? "None"),
      limit(30)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const users = [];
      querySnapshot.forEach((doc) => {
        users.push({ id: doc.id, ...doc.data() });
      });
      setTeamMembers(users);
    });

    return unsubscribe;
  };

  useEffect(() => {
    fetchTotalSubmissions();
    const unsubscribe = fetchTeamMembers();
    fetchTotalSubmissionsEvaluated();
    return () => {
      unsubscribe();
    };
  }, [department]);

  const handlePositionUpdate = async (id, value) => {
    await updateDoc(doc(db, "users", id), {
      position: value,
    });
  };

  return (
    <>
      <AdminTopBar title="Review Dashboard" />

      <div className="container mx-auto p-6 space-y-6">
        {/* Reviewer's Info and Department Controls */}
        <div className="bg-white shadow p-6 flex justify-between items-center">
          {/* Left side: Reviewer's name and department */}
          <div>
            <h2 className="text-2xl font-bold mb-2">
              Reviewer: {userData.displayName}
            </h2>
            {userData?.role === "Reviewer" && (
              <p className="text-gray-700">
                Department (Bureau): {userData?.department}
              </p>
            )}
          </div>

          {/* Right side: Department dropdown and View Report button */}
          {userData?.role === "Administrator" && (
            <div className="flex items-center space-x-4">
              <select
                value={department}
                onChange={handleDepartmentChange}
                className="border border-gray-300 p-2 "
              >
                <option value="Pathogen Economy">Pathogen Economy</option>
                <option value="Mobility">Mobility</option>
                <option value="Infrastructure Innovations">
                  Infrastructure Innovations
                </option>
                <option value="Industry 4.0+">Industry 4.0+</option>
                <option value="Aeronautics and Space">
                  Aeronautics and Space
                </option>
                <option value="Productivity Acceleration">
                  Productivity Acceleration
                </option>
                <option value="STI Support Services">
                  STI Support Services
                </option>
              </select>

            </div>
          )}
        </div>

        {/* Submissions Info */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Submissions Received */}
          <div className="bg-blue-100 text-blue-900 p-6  shadow">
            <h3 className="text-lg font-bold mb-2">Submissions Received</h3>
            <p className="text-4xl font-semibold">{totalSubmissions ?? 0}</p>
          </div>

          {/* Submissions Evaluated */}
          <div className="bg-green-100 text-green-900 p-6  shadow">
            <h3 className="text-lg font-bold mb-2">Submissions Evaluated</h3>
            <p className="text-4xl font-semibold">
              {totalSubmissionsEvaluated ?? 0}
            </p>
          </div>
        </div>

        {/* Team Members */}
        <div className="bg-white shadow p-6 ">
          <h3 className="text-lg font-bold mb-4">
            Team Members in {userData?.department}
          </h3>
          <ul className="list-disc pl-6 space-y-4">
            {teamMembers.map((member, index) => (
              <li
                key={index}
                className="text-gray-700 flex justify-between items-center"
              >
                <span>{member.displayName}</span>
                {userData?.role === "Administrator" && (
                  <select
                    value={member.position ?? "Member"}
                    onChange={(e) =>
                      handlePositionUpdate(member.id, e.target.value)
                    }
                    className="border border-gray-300 p-2 "
                  >
                    <option value="Member">Member</option>
                    <option value="Team Lead">Team Lead</option>
                  </select>
                )}
              </li>
            ))}
            {teamMembers.length === 0 && (
              <p className="text-gray-700">No team members found.</p>
            )}
          </ul>
        </div>

        {/* General Observations */}
        <div className="bg-white shadow p-6 ">
          <h3 className="text-lg font-bold mb-4">General Observations</h3>
          <ul className="list-disc pl-6 space-y-2">
            {userData.comments?.map((comment, index) => (
              <li key={index} className="text-gray-700">
                {comment}
              </li>
            ))}

            {userData.comments?.length === 0 && (
              <p className="text-gray-700">No comments found.</p>
            )}
          </ul>

          {/* Conditionally render textarea if the user is a Team Lead */}
          {userData.position === "Team Lead" && (
            <div className="mt-6">
              <textarea
                id="observations"
                rows="4"
                className="w-full border border-gray-300 p-2"
                value={observations}
                onChange={handleObservationChange}
              />
              <button
                onClick={handleSubmitObservations}
                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Submit Observations
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ReviewDashboard;
