import React, { useState } from 'react';
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../Services/firebase-config"; // Import the auth object from your firebase-config file

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Send password reset email
      await sendPasswordResetEmail(auth, email);
      setIsSubmitted(true);
    } catch (error) {
      console.error("Error sending password reset email: ", error);
      setError("Failed to send password reset email. Please try again.");
    }
  };

  return (
    <div className="h-screen bg-cover bg-center flex flex-col justify-center items-center" style={{ backgroundImage: `url('/Images/background.jpg')` }}>
      <div className="max-w-md w-full bg-custom-blue-1 p-8 border shadow">
        <div className="flex justify-center mb-8">
          <img className="w-20 " src={"/Images/STIlogo.svg"} alt="STI Logo" />
        </div>
        <h2 className="text-2xl font-normal mb-2 text-white text-center">Forgot Password</h2>
        <h3 className="text-sm font-small mb-10 text-white text-center">Enter your email to reset your password</h3>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm text-white font-semibold mb-2">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              className="shadow-sm bg-gray-50 py-2 px-4 block w-full"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <button
              type="submit"
              className="w-full bg-custom-green-1 text-custom-blue-1 py-2 px-4 border border-transparent hover:text-white"
            >
              Send Recovery Email
            </button>
          </div>
        </form>

        {isSubmitted && (
          <div className="mt-4 p-4 bg-green-100 text-green-800 rounded">
            A recovery email has been sent to <strong>{email}</strong>. Please check your inbox.
          </div>
        )}

        {error && (
          <div className="mt-4 p-4 bg-red-100 text-red-800 rounded">
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
