import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./Pages/Home";
import TopMenu from "./Components/Common/TopMenu";
import ChargingNetwork from "./Pages/ChargingNetwork"; // Make sure to define this component
import Reports from "./Pages/Reports"; // Make sure to define this component
import DashboardPage from "./Pages/Admin/Dashboard";
import LoginPage from "./Pages/Admin/LoginPage";
import SignupPage from "./Pages/Admin/SignupPage";
import StakeholdersContent from "./Components/Admin/ApplicationForm"; // Define this component
import ReportsContent from "./Components/Admin/Notifications"; // Define this component
import SettingsContent from "./Components/Admin/SettingsContent";
import SideNav from "./Components/Admin/SideNav";
import DashboardContent from "./Components/Admin/DashboardContent";
import StakeholderPage from "./Pages/Admin/StakeholderPage";
import StakeholderDetails from "./Components/Admin/stakeholder/StakeholderDetails";
import StakeholderSideNav from "./Components/Admin/stakeholder/StakeholderSideNav";
import BasicInfo from "./Components/Admin/stakeholder/BasicInfo";
import Operations from "./Components/Admin/stakeholder/Operations";
import Sales from "./Components/Admin/stakeholder/Sales/Sales";
import HumanResource from "./Components/Admin/stakeholder/HumanResource";
import Financials from "./Components/Admin/stakeholder/Financials";
import ChargingInfrastructure from "./Components/Admin/stakeholder/ChargingInfrastructure";
import Energy from "./Components/Admin/stakeholder/Energy";
import Planning from "./Components/Admin/stakeholder/Planning";
import Ebus from "./Pages/Ebus";
import { useAuth } from "./Contexts/AuthContext";
import ForgotPasswordPage from "./Pages/Admin/ForgotPassword";
import ApplicationForm from "./Components/Admin/ApplicationForm";
import NotificationsPage from "./Components/Admin/Notifications";
import EmailVerification from "./Pages/Admin/EmailVerification";
import Users from "./Components/Admin/Users";
import ReviewDashboard from "./Components/Admin/ReviewDashboard";
import Submissions from "./Components/Admin/Submissions";
import AdminDashboard from "./Components/Admin/AdminDashboard";
import ApplicantReview from "./Components/Admin/ApplicantReview";
import Support from "./Components/Admin/Support";
import FileViewer from "./Components/Admin/FileViewer";
import DepartmentReport from "./Components/Admin/DepartmentReport";
import Inbox from "./Components/Admin/Inbox";

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route
          path="/login"
          element={
            <AuthRoute>
              <LoginPage />
            </AuthRoute>
          }
        />
        <Route
          path="/forgotpassword"
          element={
            <AuthRoute>
              <ForgotPasswordPage />
            </AuthRoute>
          }
        />
        <Route
          path="/register"
          element={
            <AuthRoute>
              <SignupPage />
            </AuthRoute>
          }
        />
        <Route
          path="/verify-email"
          element={
            <AuthRoute>
              <EmailVerification />
            </AuthRoute>
          }
        />
        <Route path="/charging-network" element={<ChargingNetwork />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/ebus" element={<Ebus />} />
        <Route
          path="/*"
          element={
            <ProtectedRoute>
              <SideNav>
                <Routes>
                  <Route index element={<DashboardContent />} />
                  <Route
                    path="application-form"
                    element={<ApplicationForm />}
                  />
                  <Route path="/update-application/:id" element={<ApplicationForm updateMode={true} />} />
                  <Route path="/department-report" element={<DepartmentReport />} />
                  <Route path="/file-viewer" element={<FileViewer />} />
                  <Route path="notifications" element={<NotificationsPage />} />
                  <Route path="users" element={<Users />} />
                  <Route path="review" element={<ReviewDashboard />} />
                  <Route path="inbox" element={<Inbox />} />
                  <Route
                    path="submissions/*"
                    element={
                      <Routes>
                        <Route index element={<Submissions />} />
                        <Route path=":id" element={<ApplicantReview />} />
                      </Routes>
                    }
                  />
                  <Route path="adminsdashboard" element={<AdminDashboard />} />
                  <Route path="support" element={<Support />} />
                </Routes>
              </SideNav>
            </ProtectedRoute>
          }
        />
        <Route
          path="/stakeholder/:id/*"
          element={
            <StakeholderSideNav>
              <Routes>
                <Route index element={<StakeholderDetails />} />
                <Route path="details" element={<StakeholderDetails />} />
                <Route path="basicinfo" element={<BasicInfo />} />
                <Route path="operations" element={<Operations />} />
                <Route path="sales" element={<Sales />} />
                <Route path="humanresource" element={<HumanResource />} />
                <Route path="financials" element={<Financials />} />
                <Route
                  path="charginginfrastructure"
                  element={<ChargingInfrastructure />}
                />
                <Route path="energy" element={<Energy />} />
                <Route path="planning" element={<Planning />} />
              </Routes>
            </StakeholderSideNav>
          }
        ></Route>
      </Routes>
    </Router>
  );
}

const ProtectedRoute = ({ children }) => {
  const { currentUser } = useAuth();

  if (!currentUser) {
    return <Navigate to="/register" />;
  }
  if (currentUser && !currentUser.emailVerified) {
    return <Navigate to="/verify-email" />;
  }

  return children;
};

const AuthRoute = ({ children }) => {
  const { currentUser } = useAuth();

  if (currentUser && currentUser.emailVerified) {
    return <Navigate to="/" />;
  }

  return children;
};

export default App;
