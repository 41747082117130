// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'; // Import for Firebase Storage
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCVpwdpeXMHuszVEzn8LUyN0xWRomxq938",
  authDomain: "sti-grant-uganda.firebaseapp.com",
  projectId: "sti-grant-uganda",
  storageBucket: "sti-grant-uganda.appspot.com",
  messagingSenderId: "407005430502",
  appId: "1:407005430502:web:abf5585b2cd304975db127",
  measurementId: "G-5M18DMWJ6M"
};


const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const db = getFirestore(app);
// Initialize Firebase Storage
const storage = getStorage(app);

// Initialize Firebase Analytics
const analytics = getAnalytics(app);

export {db, storage };

