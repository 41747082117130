import React, { useEffect } from "react";
import AdminTopBar from "./AdminTopBar";
import moment from "moment";
import { doc, collection, updateDoc, onSnapshot, where, query, writeBatch } from "firebase/firestore";
import { db } from "../../Services/firebase-config.js";
import { useAuth } from "../../Contexts/AuthContext";

const NotificationsPage = () => {
  const { currentUser } = useAuth();
  const [notifications, setNotifications] = React.useState([]);

  useEffect(() => {
    const unsubscribe = fetchFormData();
    return () => {
      unsubscribe();
    };
  }, []);

  const fetchFormData = () => {
    const userNotificationsQuery = query(
      collection(db, "notifications"),
      where("userId", "==", currentUser.uid)
    );

    const generalNotificationsQuery = query(
      collection(db, "notifications"),
      where("userId", "==", null)
    );

    const unsubscribeUserNotifications = onSnapshot(userNotificationsQuery, (userQuerySnapshot) => {
      const userNotifications = userQuerySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const unsubscribeGeneralNotifications = onSnapshot(generalNotificationsQuery, (generalQuerySnapshot) => {
        const generalNotifications = generalQuerySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const combinedNotifications = [...userNotifications, ...generalNotifications];
        setNotifications(combinedNotifications);

        // Call the function to mark unread notifications as read
        markNotificationsAsRead(combinedNotifications);
      });

      return () => {
        unsubscribeUserNotifications();
        unsubscribeGeneralNotifications();
      };
    });

    return unsubscribeUserNotifications;
  };

  // Mark unread notifications as read when they are rendered
  const markNotificationsAsRead = async (notifications) => {
    const batch = writeBatch(db);

    notifications.forEach((notification) => {
      if (!notification.read) {
        const notificationDocRef = doc(db, "notifications", notification.id);
        batch.update(notificationDocRef, { read: true });
      }
    });

    try {
      await batch.commit(); // Apply batch update
    } catch (error) {
      console.error("Error updating notification status: ", error);
    }
  };

  return (
    <>
      <AdminTopBar title="Notifications" />

      <div className="p-5 max-w-3xl mx-auto">
        {notifications?.length > 0 ? (
          notifications.map((notification, index) => (
            <div
              key={index}
              className={`flex justify-between items-center border p-4 mb-4 shadow-sm rounded-lg ${
                notification.read ? "bg-gray-100" : "bg-blue-100"
              }`} // Highlight unread notifications
            >
              <span className="text-gray-700">{notification.message}</span>
              <span className="text-gray-500 text-sm">
                {moment(notification.timestamp.toDate()).isSame(moment(), "day")
                  ? moment(notification.timestamp.toDate()).format("h:mm A")
                  : moment(notification.timestamp.toDate()).format("MMM D, YYYY")}
              </span>
            </div>
          ))
        ) : (
          <div className="text-center text-gray-500 mt-8">
            No notifications yet.
          </div>
        )}
      </div>
    </>
  );
};

export default NotificationsPage;
