import React from 'react';
import AdminTopBar from './AdminTopBar';
import { MailIcon, MapIcon, GlobeAltIcon, PhoneIcon } from "@heroicons/react/outline"; // Import the icons

const Support = () => {
  return (
    <>
      <AdminTopBar title="Support" />

      <div className="container mx-auto p-6 space-y-6">
        {/* STI Contact Details Section */}
        <div className="bg-white border border-gray-300  p-6">
          <h2 className="text-2xl font-bold mb-4 text-custom-blue-1">Contact Us</h2>

          <div className="text-gray-700 space-y-4">
            <p className="flex items-center">
              <MailIcon className="h-5 w-5 text-gray-500 mr-2" />
              <span className="font-semibold">Email:</span> 
              <a href="mailto:grants@sti.go.ug" className="text-blue-500 hover:underline ml-2">grants@sti.go.ug</a>
            </p>
            <p className="flex items-center">
              <MapIcon className="h-5 w-5 text-gray-500 mr-2" />
              <span className="font-semibold">Address:</span> 
              <span className="ml-2">STI-OP Office, Plot 106 Katalima Road, Naguru</span>
            </p>
            <p className="flex items-center">
              <GlobeAltIcon className="h-5 w-5 text-gray-500 mr-2" />
              <span className="font-semibold">Website:</span> 
              <a href="https://www.sti.go.ug" className="text-blue-500 hover:underline ml-2">www.sti.go.ug</a>
            </p>
                {/* Phone Numbers */}
    <p className="flex items-center">
      <PhoneIcon className="h-5 w-5 text-gray-500 mr-2" />
      <span className="font-semibold">Phone:</span>
      <span className="ml-2">+256773308792, +256701822708</span>
    </p>
          </div>

          {/* Social Media Section */}
          <div className="mt-8">
            <h3 className="text-xl font-semibold">Follow Us</h3>
            <p className="text-gray-700">Stay connected for updates and opportunities:</p>
            <p>
              <a href="https://x.com/STIsecretariat" className="text-blue-500 hover:underline">x.com/STIsecretariat</a>
            </p>
          </div>
        </div>

{/* National Science Week Section */}
<div className="bg-white border border-gray-300 p-6">
  <h3 className="text-2xl font-bold text-custom-blue-1 mb-4">National Science Week 2024</h3>
  
  <div className="flex flex-col md:flex-row items-center md:space-x-6 space-y-4 md:space-y-0">
    {/* Portrait Image */}
    <img
      src="/Images/nsw.jpg" // Replace with the actual image link
      alt="National Science Week"
      className="w-full md:w-1/3 h-auto "
    />
    
    {/* Text Section */}
    <div className="text-gray-700 space-y-4 md:w-1/2">
      <p>
        The National Science Week 2024 is Uganda’s premier event, showcasing groundbreaking innovations 
        across various sectors. This year, we celebrate the theme <strong>“Made in Uganda; Innovation to Market.”</strong> 
        It’s a gathering of innovators, researchers, entrepreneurs, and thought leaders dedicated to advancing Uganda’s 
        scientific and technological landscape.
      </p>
      <p>
        <strong>Dates:</strong> 5th - 10th November 2024 <br />
      </p>
      <p>
        Attendees will experience live demonstrations, panel discussions, and networking opportunities with industry leaders. 
        Exhibitors will showcase their innovations and explore pathways to bring their ideas to the market.
      </p>
      <p>
        Interested in showcasing your innovation? <strong>Apply as an exhibitor</strong> and be part of the future of Uganda’s 
        science and technology ecosystem.
      </p>
      <br></br>
      {/* Link to Learn More */}
      <a
        href="https://bit.ly/nsw24"
        target="_blank" // Open in a new tab
        rel="noopener noreferrer"
        className="bg-custom-blue-1 text-white py-2 px-4  hover:bg-blue-600 transition duration-300 ease-in-out"
      >
        Learn More About NSW 2024
      </a>
    </div>
  </div>
</div>


        {/* Feedback Form Section */}
        <div className="bg-white border border-gray-300  p-6">
          <h3 className="text-2xl font-bold text-custom-blue-1 mb-4">Feedback Survey</h3>
          <div className="w-full">
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSd29qbyM34KLG5HGzwOnqclTdGgBenBo6YPw7brUKbLQdBrjQ/viewform?embedded=true"
              width="100%"
              height="600"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
              title="Feedback Form"
            >
              Loading…
            </iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
