import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom"; // React Router v6
// Use this function when the user submits the login form
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../Services/firebase-config"; // Import the auth object from your firebase-config file

// Import other necessary components and hooks

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    setError(null);
    event.preventDefault();
    // Handle the login logic here
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      // User logged in
      // Redirect to the admin dashboard or perform other login success actions
      navigate("/");
    } catch (error) {
      // Handle errors such as wrong password, no user, etc.
      console.error("Error logging in: ", error);
      if (error.code === "auth/invalid-credential") {
        setError("Invalid email or password");
      } else {
        setError("An error occurred. Please try again.");
      }
    }
  };

  return (
    <div
      className="h-screen bg-cover bg-center flex flex-col justify-center items-center"
      style={{ backgroundImage: `url('/Images/background.jpg')` }}
    >
      <div className="max-w-md w-full bg-custom-blue-1 p-8 border  shadow">
        <div className="flex justify-center mb-8">
          <img className="w-20 " src={"/Images/STIlogo.svg"} alt="STI  Logo" />
        </div>
        <h2 className="text-2xl font-normal mb-2 text-white text-center">
          LOGIN
        </h2>
        <h3 className="text-sm font-small mb-10 text-white text-center">
          Welcome back to the STI Grant Application Portal
        </h3>
        {error && (
          <div className="mb-4 p-4 bg-red-100 text-red-800 rounded">
            {error}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm  text-white font-semibold mb-2"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="shadow-sm bg-gray-50   py-2 px-4 block w-full"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-sm text-white font-semibold mb-2"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              className="shadow-sm bg-gray-50  py-2 px-4 block w-full"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center">
              <Link
                to="/register"
                className="text-sm text-white cursor-pointer hover:underline"
              >
                Don't have an account?{" "}
                <span className="font-bold">SIGN UP</span>
              </Link>
            </div>

            <div className="text-sm">
              <Link
                to="/forgotpassword"
                className="font-small text-white hover:text-custom-green-1"
              >
                Forgot Password?
              </Link>
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="w-full bg-custom-green-1 text-custom-blue-1 py-2 px-4 border border-transparent   hover:text-white"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
