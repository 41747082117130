import React, { useState, useEffect, useRef } from "react";
import { BellIcon, LogoutIcon } from "@heroicons/react/outline";
import { useAuth } from "../../Contexts/AuthContext";
import { signOut } from "firebase/auth"; // Import signOut from Firebase
import { useNavigate } from "react-router-dom"; // Use for redirect after logout
import { auth, db } from "../../Services/firebase-config"; // Import Firebase auth and db
import { collection, query, where, onSnapshot } from "firebase/firestore"; // Firestore functions

const AdminTopBar = ({ title }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for dropdown
  const { currentUser } = useAuth();
  const [unreadCount, setUnreadCount] = useState(0); // Unread notifications count
  const navigate = useNavigate();
  const dropdownRef = useRef(null); // Ref to track the dropdown

  // Fetch unread notifications count
  useEffect(() => {
    if (currentUser) {
      const notificationsRef = collection(db, "notifications");
      const unreadQuery = query(
        notificationsRef,
        where("userId", "==", currentUser.uid),
        where("read", "==", false)
      );

      const unsubscribe = onSnapshot(unreadQuery, (snapshot) => {
        setUnreadCount(snapshot.size); // Update unread count
      });

      return () => unsubscribe();
    }
  }, [currentUser]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleLogout = async () => {
    try {
      await signOut(auth); // Sign out from Firebase
      navigate("/"); // Redirect to homepage
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  // Close the dropdown if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false); // Close dropdown
      }
    };

    // Add event listener when component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <>
      <div className="flex items-center justify-between bg-white px-4 py-2 shadow-sm">
        {/* Mobile View - How to Apply on the left */}
        <button
          className="bg-gray-200 flex items-center px-3 py-1 text-sm font-medium text-black hover:bg-gray-300 md:hidden"
          onClick={openModal}
        >
          <img
            src="/Images/youtube.svg"
            alt="How to Apply"
            className="h-4 w-4 mr-2"
          />
          HOW TO APPLY!
        </button>

        {/* Title Section (visible on larger screens, hidden on mobile) */}
        <div className="hidden md:block">
          <h1 className="text-xl font-bold">{title}</h1>
        </div>

        {/* Right Side Content for Large Screens */}
        <div className="flex items-center space-x-4 relative">
          {/* Large Screen - How to Apply on the right */}
          <button
            className="hidden md:flex bg-gray-200 items-center px-3 py-1 text-sm font-medium text-black hover:bg-gray-300"
            onClick={openModal}
          >
            <img
              src="/Images/youtube.svg"
              alt="How to Apply"
              className="h-4 w-4 mr-2"
            />
            HOW TO APPLY!
          </button>

          {/* Notification Icon with Unread Count */}
          <div className="relative">
            <BellIcon className="h-6 w-6 text-black" />
            {unreadCount > 0 && (
              <span className="absolute top-0 right-0 bg-red-500 text-white text-xs rounded-full px-1">
                {unreadCount}
              </span>
            )}
          </div>

          {/* User Profile and Dropdown */}
          <div
            className="flex items-center space-x-2 cursor-pointer"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            ref={dropdownRef} // Attach ref to dropdown container
          >
            <img
              src={currentUser?.photoURL || "/Images/user.svg"}
              alt="User profile"
              className="h-8 w-8 rounded-full"
            />
            <span className="text-sm font-medium hidden md:block">
              {currentUser?.displayName ?? currentUser?.email ?? ""}
            </span>
            {/* Dropdown Icon */}
            <svg
              className="w-4 h-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>

          {/* Dropdown Menu */}
          {isDropdownOpen && (
            <div
              className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 shadow-lg py-2"
              ref={dropdownRef} // Attach ref to dropdown container
            >
              <button
                onClick={handleLogout}
                className="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-100 w-full"
              >
                <LogoutIcon className="w-5 h-5 mr-2" />
                Log out
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Modal for YouTube Video */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6  w-full max-w-3xl h-auto">
            {/* Modal Header */}
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">How to Apply</h2>
              <button
                onClick={closeModal}
                className="text-black hover:text-red-500"
              >
                X
              </button>
            </div>

            {/* Video Section with Aspect Ratio */}
            <div className="relative w-full pb-[56.25%] h-0">
              <iframe
                className="absolute top-0 left-0 w-full h-full "
                src="https://www.youtube.com/embed/kk6Joupl12Y?si=VTscawsHJo4pcfYH"
                title="How to Apply Video"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminTopBar;
