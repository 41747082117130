import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { PhoneIcon } from "@heroicons/react/outline"; // Import the PhoneIcon

import {
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "../../Services/firebase-config";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../Services/firebase-config.js";

// State for the popup visibility

const SignupPage = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    setError(null);
    event.preventDefault();

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await updateProfile(userCredential.user, {
        displayName: fullName,
      });

      const userRef = doc(db, "users", userCredential.user.uid);
      await setDoc(
        userRef,
        { lastAccessed: new Date(), displayName: fullName },
        { merge: true }
      );

      await sendEmailVerification(userCredential.user);
      navigate("/");
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        setError("The email address is already in use by another account.");
      } else if (error.code === "auth/weak-password") {
        setError("Password should be at least 6 characters");
      } else {
        setError("Something went wrong. Please try again later.");
      }
      console.error("Error signing up: ", error);
    }
  };

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };
  return (
    <div
      className="h-screen bg-cover bg-center flex flex-col justify-center items-center"
      style={{ backgroundImage: `url('/Images/background.jpg')` }}
    >
      <div className="max-w-md w-full bg-custom-blue-1 p-8 border  shadow">
        <div className="flex justify-center mb-8">
          <img className="w-20 " src={"/Images/STIlogo.svg"} alt="STI  Logo" />
        </div>
        <h2 className="text-2xl font-normal mb-2 text-white text-center">
          REGISTER TO CREATE ACCOUNT
        </h2>
        <h3 className="text-sm font-small mb-10 text-white text-center">
          Welcome to the STI Grant Application Portal! Create an account to get
          started with your application and participate in the latest call for
          innovations.
        </h3>

        {error && (
          <div className="mb-4 p-4 bg-red-100 text-red-800 rounded">
            {error}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="fullName"
              className="block text-sm  text-white font-semibold mb-2"
            >
              Full Name
            </label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              className="shadow-sm bg-gray-50   py-2 px-4 block w-full"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm  text-white font-semibold mb-2"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="shadow-sm bg-gray-50   py-2 px-4 block w-full"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-sm text-white font-semibold mb-2"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              className="shadow-sm bg-gray-50  py-2 px-4 block w-full"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="flex items-center justify-between mb-6">
  {/* Already have an account */}
  <div className="text-sm">
    <Link
      to="/login"
      className="font-small text-white hover:text-custom-green-1"
    >
      Already have an account?{" "}
      <span className="font-bold">LOGIN</span>
    </Link>
  </div>

  {/* Need Assistance Section */}
  <div className="text-sm">
    <button
      type="button"  // Set to button to prevent default form submission behavior
      className="text-white text-sm font-semibold hover:underline"
      onClick={togglePopup}
    >
      Need Assistance?
    </button>
  </div>

  {/* Popup */}
  {isPopupVisible && (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 shadow-md max-w-sm w-full">
        <h3 className="text-lg font-bold mb-4">Need Assistance?</h3>
        <p className="text-gray-700 mb-4">
          You can reach out to us using the following phone numbers:
        </p>
        <p className="flex items-center mb-2">
          <PhoneIcon className="h-5 w-5 text-gray-500 mr-2" />
          <span>+256773308792</span>
        </p>
        <p className="flex items-center mb-4">
          <PhoneIcon className="h-5 w-5 text-gray-500 mr-2" />
          <span>+256701822708</span>
        </p>
        <button
          className="bg-custom-blue-1 text-white py-2 px-4 hover:bg-blue-600 transition duration-300 ease-in-out w-full"
          onClick={togglePopup}
        >
          Close
        </button>
      </div>
    </div>
  )}
</div>

          <div>
            <button
              type="submit"
              className="w-full bg-custom-green-1 text-custom-blue-1 py-2 px-4 border border-transparent hover:text-white"
            >
              Sign Up
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignupPage;
